.swal2-styled.swal2-confirm {
    background-color: #435EEF;
}
.swal2-styled.swal2-confirm:focus {
    box-shadow: 0 0 0 3px rgb(67 94 239 / 49%);
}
.swal2-actions .swal2-cancel:focus{
    box-shadow: 0 0 0 3px rgb(221 51 51 / 48%);
}
.swal2-styled {
    margin: .3125em;
    padding: 8px 14px;
    transition: box-shadow .1s;
    box-shadow: 0 0 0 3px rgba(0,0,0,0);
    font-weight: 500;
}