@font-face {
    font-family: Orbitron;
    font-style: normal;
    font-weight: 400 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/orbitron/v31/yMJRMIlzdpvBhQQL_Qq7dy0.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: Sora;
    font-style: normal;
    font-weight: 100 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sora/v12/xMQbuFFYT72XzQspDre2.woff2) format("woff2");
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: Sora;
    font-style: normal;
    font-weight: 100 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/sora/v12/xMQbuFFYT72XzQUpDg.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.newtheme {
    --bs-body-font-family: Orbitron;
    background-color: #090e19;
}

.pr-0 {
    padding-right: 0 !important;
}

body .home2 h1,
body .home2 h2,
body .home2 h3,
body .home2 h4,
body .home2 h5,
body .home2 h6 {
    font-weight: 600;
    font-family: Orbitron;
    letter-spacing: 0.64px;
}

body .home2 li,
body .home2 p {
    font-family: Sora;
    font-size: 16px;
}

.newtheme .table>tbody td,
.newtheme .table>thead td,
.newtheme .table>thead th,
body .home2 span {
    font-family: Sora;
}

body .home2 p {
    line-height: 26px;
}

.light-grey {
    color: #d8d8d8;
}

.newtheme .btn {
    padding: 10px 15px;
    letter-spacing: 0.64px;
}

.text-span-1 {
    display: inline-block;
    background-image: linear-gradient(90deg, #fff 0, #8ff3ae 46.82%, #4ebacf 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-span-1::selection {
    background-color: rgb(255, 255, 255);
    -webkit-text-fill-color: black;
    /* Ensure text is visible */
    color: black;
}

.small,
small {
    font-size: 0.875rem !important;
}

.hero-banner {
    background-image: url(../images/banner.webp);
    background-position: center;
    background-size: cover;
}

.event_bg,
.newtheme .btn-primary,
.partner_bg,
.priceBox,
.tab_style2 .nav-pills .nav-link {
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.Roadmap .swiper {
    padding-top: 50px;
    height: 540px;
}

.hero-banner h1 {
    line-height: 1.4;
}

body .hero-banner p {
    font-size: 20px;
}

.newtheme .hero-banner .btn {
    padding: 15px 40px;
}

.hero-banner .social-icon {
    position: absolute;
    transform: rotate(-90deg);
    top: 45%;
    display: none;
    left: -90px;
}

.newtheme .hero-banner .social-icon ul li img {
    transform: rotate(90deg);
}

.hero-banner .coin_ellipse {
    width: 270px;
    margin: 0 auto;
}

.priceBox .icon,
.tab_style2 .nav-pills .nav-item {
    margin-right: 15px;
}

.hero-banner .coin_ellipse img {
    object-fit: contain;
    width: 270px;
}

.newtheme .btn-primary {
    color: #fff;
    background-image: url(../images/btnbg.png);
    border: none;
    margin-top: 10px;
}

.footerRight {
    float: right;
}

.priceBox {
    display: flex;
    align-items: center;
    padding: 10px 12px;
    border-radius: 15px;
    position: absolute;
    top: 168px;
    right: 0;
    z-index: 3;
    animation: 5s linear infinite animate-2;
    background-image: url(../images/price_bg.png);
}

.priceBox .icon img {
    width: 55px;
}

.priceBox .icon {
    filter: drop-shadow(0px 15px 20px rgba(164, 128, 255, 0.5));
}

.priceBox .content p {
    color: #fff;
    font-size: 14px;
    margin-bottom: 0;
}

.priceBox .content h5 {
    color: #fff;
    font-size: 20px;
    margin-bottom: 0;
}

@keyframes animate-2 {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-15vmin);
    }
}

.partner_bg {
    background-image: url(../images/partner_bg.png);
}

.partner img {
    height: 30px;
    object-fit: contain;
}

.ChannelPartners img,
.Inthenews img {
    height: 50px;
    object-fit: contain;
}

.circleIcon {
    max-width: 70px;
    padding: 12px;
}

.gradient-box {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    color: #fff;
    background: padding-box #0b111d;
    position: relative;
    border: 3px solid transparent;
    border-radius: 50%;
}

.gradient-box:before {
    content: "";
    position: absolute;
    top: 4px;
    right: 4px;
    bottom: 4px;
    left: 4px;
    z-index: -1;
    border-radius: inherit;
    background: linear-gradient(to right, #007a8b, #df2c99);
}

.card-style .card {
    border-radius: 20px;
    background: linear-gradient(103deg, #090e19 32.59%, #021c22 98.36%);
    backdrop-filter: blur(5.5px);
    box-shadow: none;
    margin-bottom: 0;
}

.rightellipse {
    width: 40%;
}

.bottomelllipse {
    width: 30%;
    right: 30%;
}

.card-style2 .card {
    border-radius: 24px;
    box-shadow: none;
    position: relative;
}

.card-style2 .card::before {
    background: linear-gradient(180deg, #929597 0, #090e19 70%) !important;
}

.card-style2 .gradient-box {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    color: #fff;
    background: linear-gradient(180deg, #14171f 7.67%, #222630 30.32%, #090e19 80%);
    position: relative;
    background-clip: padding-box;
    border: 3px solid transparent;
}

.card-style2 .card .innerCard {
    padding: 45px 6px;
}

.Ecosystem .card-style2 .card .innerCard {
    padding: 45px 6px;
    min-height: 218px;
    display: flex;
    align-items: center;
}

.gradient-circle-icon {
    border-radius: 500px;
    background: var(--Gradient-Verticle, linear-gradient(222deg, #53d0d3e0 15.67%, #5d5be3d9 53.32%, #de1890b8 100%));
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto auto -36px;
    position: relative;
    z-index: 1;
}

.gradient-circle-icon img,
.gradient-circle-icon svg {
    width: 40px;
    object-fit: contain;
    height: 40px;
}

.card-style3 .card {
    border-radius: 24px;
    border: 1px solid rgba(240, 240, 240, 0.2);
    background: #0b000e;
    box-shadow: none;
    overflow: hidden;
}

.card-style3 .card>img {
    border-radius: 24px;
}

.Ourproduct .card-style3 .card {
    margin-bottom: 0;
}

.Roadmap .card-style4 .card,
.Roadmap .twentyone .card-style4 .card,
.Roadmap .twentytwo .card-style4 .card {
    min-height: 243px;
}

.Roadmap .twentyone .card-style4 .card {
    min-height: 220px;
}

.Roadmap .twentythree .card-style4 .card {
    min-height: 353px;
}

.Roadmap .twentyfour .card-style4 .card {
    min-height: 311px;
}

.innerCard {
    overflow-y: scroll;
    max-height: 250px;
}

.tos_p {
    color: #e3e3e3;
    letter-spacing: 1.5px;
}

.innerCard::-webkit-scrollbar {
    display: none;
}

@media screen and (min-device-width: 200px) and (max-device-width: 768px) {
    .innerCard {
        overflow-y: scroll;
        max-height: 204px;
    }
}

.roadmap_bg {
    background: url(../images/roadmap_bg.png) center bottom/100% no-repeat;
}

.Roadmap {
    position: relative;
}

.leftpink,
.linelabel,
.rightyellow {
    position: absolute;
}

.card-style4 .card {
    border-radius: 12px;
    border: 1px solid #2f2e2e;
    background: var(--main-blue, #0b111d);
    box-shadow: none;
}

.Roadmap .card .innerCard ul {
    list-style-type: initial;
    padding-left: 1rem;
}

.card-style4 .card ul li {
    margin-top: 5px;
}

.Roadmap .swiper-slide .line {
    display: block;
    width: 1px;
    height: 100px;
}

.Roadmap .nav-link {
    padding: 0 1rem;
    font-size: 20px;
}

.linelabel {
    left: 0;
    top: -100px;
}

.linelabel svg {
    width: 25px;
}

.Tokenomics {
    background: url(../images/planet-earth-background.png) center bottom/100% no-repeat;
}

.piechart_bg {
    background-image: url(../images/piechart_bg.png);
    background-color: #081a30;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center bottom;
}

.piechart {
    box-shadow: none;
}

.piechart img {
    height: 500px;
    object-fit: contain;
}

.tab_style1 .nav-pills .nav-link.active,
.tab_style1 .nav-pills .show>.nav-link {
    color: #ce3298;
    background-color: transparent;
}

.tab_style1 .nav-link:focus,
.tab_style1 .nav-link:hover {
    color: #ce3298;
}

.accordion_style1 .accordion .accordion-button:not(.collapsed) {
    border-radius: 4px;
    background: var(--GRADIENT, linear-gradient(90deg, #05d6d9bf 0.3%, #f907fcb8 99.65%));
    color: #fff;
}

.accordion_style1 .accordion .accordion-button {
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid var(--Dark, #323232);
    background: var(--main-blue, #0b111d);
}

.accordion_style1 .accordion-button {
    color: #fff;
}

.accordion_style1 .accordion-button:not(.collapsed)::after {
    transform: rotate(0);
}

.accordion_style1 .accordion .accordion-item {
    background-color: transparent;
}

.accordion_style1 .accordion .accordion-button:not(.collapsed)::after {
    background-image: none;
    font-family: bootstrap-icons !important;
    font-weight: 700;
    font-size: 25px;
    margin-top: -5px;
    content: "\f2ea";
}

.accordion_style1 .accordion .accordion-button::after {
    background-image: none;
    font-family: bootstrap-icons !important;
    font-weight: 700;
    font-size: 25px;
    margin-top: -5px;
    content: "\f4fe";
}

.tab_style2 .nav-pills .nav-link.active,
.tab_style2 .nav-pills .show>.nav-link {
    background: linear-gradient(80deg, #c64eff, #00cdff);
    border: 1px solid transparent;
}

.tab_style2 .nav-pills .nav-item:last-child {
    margin-right: 0;
}

.tab_style2 .nav-pills .nav-link {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    color: #fff;
    background-image: url(../images/btnbg2.png);
    position: relative;
    background-clip: padding-box;
}

.tab_style2 .nav-pills .nav-link:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -5px;
    border-radius: inherit;
}

.Event {
    background-color: #02131f;
}

.event_bg {
    background-image: url(../images/event_bg.png);
}

.border-gradient {
    border: 5px solid;
    border-image-slice: 1;
}

.border-gradient-purple {
    border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
}

.eventdetailImg img {
    border-radius: 5px;
    margin-bottom: 40px;
    object-fit: cover;
    object-fit: fill;
    width: 100%;
}

.nft_pic.nft_multi a {
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    float: left;
    overflow: hidden;
    position: relative;
    width: calc(50% - 10px);
}

.nft_pic.nft_multi img {
    height: 267px;
    object-fit: cover;
}

.collapsImg img {
    height: 215px;
    width: 100%;
}

.nft_pic.nft_multi a:first-child {
    margin: 0 5px 5px 0;
    height: 267px;
}

.nft_pic.nft_multi a:nth-child(2) {
    margin: 0 0 5px 5px;
    height: 267px;
}

.nft_pic.nft_multi a:nth-child(3) {
    margin: 5px 5px 0 0;
    height: 267px;
}

.nft_pic.nft_multi a:nth-child(4) {
    margin: 5px 0 0 5px;
    height: 267px;
}

.rightyellow {
    right: 0;
    top: 0;
}

.leftpink {
    left: 0;
    top: 40%;
}

img.mnt_listedImg {
    aspect-ratio: 1/1;
    height: 65px;
    object-fit: cover;
    margin-bottom: 10px;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    opacity: 10 !important;
}

.thankyou_icon {
    background: var(--Gradient-Verticle, linear-gradient(222deg, #53d0d3e0 15.67%, #5d5be3d9 53.32%, #de1890b8 100%));
    height: 55px;
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 30px;
}