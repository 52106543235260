.rigcreation a {
    color: rgb(157, 81, 255);
}
/* -------------- */
.assemble_popup .modal-content {
    background-color: #000;
    border: 1px solid #c64eff94;
}

.fade.assemble_popup p {
    font-size: 17px;
    text-transform: capitalize;
}
.cog_box {
    animation: cog 2s linear infinite;
    width: 64px;
    height: 64px;
    margin: 13px auto!important;
    background-size: 100% 100%;
}
/* .cog_box1 {
    animation: cog 3s linear infinite;
    width: 40px;
    height: 40px;
    left: 76px;
    top: 10px;
    position: absolute;
    margin: 13px auto!important;
    background-size: 100% 100%;
}
.cog_box2 {
    animation: cog 2s linear infinite;
    width: 15px;
    height: 15px;
    position: absolute;
    margin: 13px auto!important;
    background-size: 100% 100%;
} */


  @keyframes cog {
    0%{
       transform: rotate(0deg);
      }
   100%{
       transform: rotate(360deg);
      }
   }